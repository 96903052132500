import { graphql, navigate } from "gatsby";

import {
  Center,
  VStack,
  Text,
  Divider,
  Heading,
  HStack,
  IconButton,
  Spacer,
  Button,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  ListItem,
  UnorderedList,
  FormControl,
  useToast,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  useDisclosure,
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Tooltip,
  Box,
  Input,
} from "@chakra-ui/react";
import Layout from "../components/Layout";
import { Steps } from "../constants";

import { ArrowBackIcon, ArrowForwardIcon } from "@chakra-ui/icons";
import { FC, useEffect, useRef, useState } from "react";
import ContactInputForm from "../components/ContactInputForm";
import { api, NotificationEntry } from "../services/api";
import axios, { AxiosError } from "axios";
import { SEO } from "../components/SEO";
import { useTranslation } from "gatsby-plugin-react-i18next";

const NotifySomeonePage = () => {
  const { t } = useTranslation();
  const [currentStep, setCurrentStep] = useState(Steps.Otp);
  const [partnerInformation, setPartnerInformation] = useState<InputItem[]>([]);
  const [code, setCode] = useState("");
  const submissionToast = useToast();

  // submission confirmation dialog state
  const {
    isOpen: isSubmitConfirmationOpen,
    onOpen: onSubmitConfirmationOpen,
    onClose: onSubmitConfirmationClose,
  } = useDisclosure();

  const handleSubmitConfirmation = async () => {
    const notifEntries: NotificationEntry[] = [];

    partnerInformation.forEach(({ type, value }) => {
      if (type === "sms") {
        notifEntries.push({ type: type, phone: value });
      } else if (type === "email") {
        notifEntries.push({ type: type, email: value });
      }
    });

    const notif = {
      notifications: notifEntries,
    };

    console.log("Posting notification: ", notif);
    console.log(code);

    api
      .createNotification(code, notif)
      .then((data) => {
        console.log("Notification submitted: ", data);
        submissionToast({
          id: "notification-submitted-toast",
          duration: 10000,
          title: t("NotifySomeonePage.notification_submitted_successfully"),
          description: t(
            "NotifySomeonePage.notification_will_be_delivered_soon"
          ),
          status: "success",
          isClosable: false,
        });
        navigate("/thank-you");
      })
      .catch((error: Error | AxiosError) => {
        if (axios.isAxiosError(error)) {
          console.log("error occured: ", error);
          const statusCode = error?.response?.status || 999;
          if (statusCode === 401) {
            submissionToast({
              id: "invalid-otp-toast",
              duration: 10000,
              title: t("NotifySomeonePage.code_is_invalid"),
              description: t(
                "NotifySomeonePage.please_check_the_code_and_re-enter"
              ),
              status: "error",
              isClosable: true,
            });
            setCurrentStep(Steps.Otp);
          } else if (statusCode === 403) {
            submissionToast({
              id: "invalid-otp-toast",
              duration: 10000,
              title: t("NotifySomeonePage.code_is_invalid"),
              description: t(
                "NotifySomeonePage.please_check_the_code_and_re-enter"
              ),
              status: "error",
              isClosable: true,
            });
            setCurrentStep(Steps.Otp);
          } else if (statusCode >= 500) {
            submissionToast({
              id: "invalid-otp-toast",
              duration: 10000,
              title: t("common.something_went_wrong"),
              description: t(
                "common.something_went_wrong_message"
              ),
              status: "error",
              isClosable: true,
            });
            setCurrentStep(Steps.Otp);
          }
        }
      });
  };

  return (
    <Layout blurBackground={true}>
      <SEO title={t("NotifySomeonePage.title")} />
      <Center w="full" h="full">
        <VStack maxW="lg" margin={3} spacing={3} align="left">
          {Steps.Otp == currentStep && (
            <OtpStep
              code={code}
              onCodeValid={(code: string) => {
                setCode(code);
                setCurrentStep(Steps.HowFar);
              }}
            />
          )}

          {Steps.HowFar == currentStep && (
            <WhoToNotifyStep
              onContinue={() => setCurrentStep(Steps.PartnerInfo)}
              onPrevious={() => setCurrentStep(Steps.Otp)}
            />
          )}
          {Steps.PartnerInfo == currentStep && (
            <PartnerInfoStep
              partnerInfo={partnerInformation}
              onPartnerInfoChange={setPartnerInformation}
              onContinue={() => setCurrentStep(Steps.Preview)}
              onPrevious={() => setCurrentStep(Steps.HowFar)}
            />
          )}
          {Steps.Preview == currentStep && (
            <PreviewStep
              onContinue={onSubmitConfirmationOpen}
              onPrevious={() => setCurrentStep(Steps.PartnerInfo)}
            />
          )}
          <SubmitConfirmation
            isOpen={isSubmitConfirmationOpen}
            onClose={onSubmitConfirmationClose}
            onConfirmed={handleSubmitConfirmation}
          />
        </VStack>
      </Center>
    </Layout>
  );
};

type OtpStepProps = {
  code: string;
  onCodeValid: (otp: string) => void;
};
const OtpStep: FC<OtpStepProps> = ({ code, onCodeValid }) => {
  const { t } = useTranslation();

  const [codeLocal, setCodeLocal] = useState(code);

  const handleSubmitCode = () => {
    // the validation here does not validate the code with the server
    // this is intentional for now (we validate when notification is submitted)

    // currently no validation is neede here as the button is disabled
    // until all chars are input

    // so we simply go ahead
    onCodeValid(codeLocal);
  };

  return (
    <>
      <Heading fontSize="3xl" textAlign={"center"}>
        {t("OtpStep.important")}
      </Heading>
      <Text fontSize="md">
        {t("OtpStep.description")}.
      </Text>

      <Divider />
      <FormControl>
        <HStack>
          <Text fontSize="md">{t("OtpStep.enter_the_code")}:</Text>
          <Spacer />
          <Input
            type="alphanumeric"
            onChange={e => setCodeLocal(e.target.value)}
            value={codeLocal}
            data-cy="pininput-0"
            size={"lg"}
            fontSize={"xl"}
            fontFamily={"monospace"}
            maxLength={10}
            htmlSize={10} width='auto'
          />
        </HStack>
        <Divider marginTop={4} marginBottom={4} />
        <HStack>
          <Spacer />
          <Tooltip
            isDisabled={codeLocal.length === 10}
            hasArrow
            label={t("OtpStep.please_enter_the_code")}
          >
            <Button
              isDisabled={codeLocal.length !== 10}
              aria-label={t("OtpStep.submit_code")}
              rightIcon={<ArrowForwardIcon />}
              onClick={handleSubmitCode}
              data-cy="btn-continue"
            >
              {t("common.continue")}
            </Button>
          </Tooltip>
        </HStack>
      </FormControl>
      <Spacer />
    </>
  );
};

type GenericStepProps = {
  onContinue: () => void;
  onPrevious?: () => void;
};

const WhoToNotifyStep: FC<GenericStepProps> = ({
  onContinue,
  onPrevious,
}) => {
  const { t } = useTranslation();
  return (
    <>
      <Heading fontSize="3xl" textAlign={"center"}>
        {t("WhoToNotifyStep.title")}
      </Heading>
      <Text fontSize="md">
        {t("WhoToNotifyStep.description")}
      </Text>
      <Text fontSize="md">
        <a href={t("WhoToNotifyStep.link.hivpoint_contract_tracing_info")} target="_blank" rel="noreferrer">{t("WhoToNotifyStep.link_text")}</a>
      </Text>

      <TipsToRememberPopover />

      <Divider />
      <HStack>
        <IconButton
          variant="outline"
          aria-label={t("common.navigate_back")}
          icon={<ArrowBackIcon />}
          onClick={onPrevious}
        />
        <Spacer />
        <Button
          onClick={onContinue}
          rightIcon={<ArrowForwardIcon />}
          data-cy="btn-continue"
        >
          {t("common.continue")}
        </Button>
      </HStack>
    </>
  );
};

type InputItem = {
  id: string;
  type: string;
  value: string;
  error: string;
};

type PartnerInfoStepProps = GenericStepProps & {
  partnerInfo: InputItem[];
  onPartnerInfoChange: (partnerInfos: InputItem[]) => void;
};

const PartnerInfoStep: FC<PartnerInfoStepProps> = ({
  onContinue,
  onPrevious,
  partnerInfo,
  onPartnerInfoChange,
}) => {
  const toast = useToast();

  const { t } = useTranslation();

  const handlePreviewButtonClick = () => {
    // remove blank and error entries for validation
    const finalPartnerInfo: InputItem[] = partnerInfo.filter(
      ({ value, error }) => value != "" && error == ""
    );

    if (finalPartnerInfo.length > 0) {
      onContinue();
    } else {
      if (!toast.isActive("no-partner-info-toast")) {
        toast({
          id: "no-partner-info-toast",
          duration: 5000,
          title: t("PartnerInfoStep.no_partner_info_added_or_invalid_input"),
          status: "error",
          isClosable: true,
        });
      }
    }
  };

  return (
    <>
      <Heading fontSize="3xl" textAlign={"center"}>
        {t("PartnerInfoStep.title")}
      </Heading>
      <Text fontSize="md">
        {t("PartnerInfoStep.fill_partners_contact_info_sms_or_email")}
      </Text>
      <VStack alignItems={"left"} spacing={5}>
        <ContactInputForm
          contactInputState={partnerInfo}
          onContactInputStateChange={onPartnerInfoChange}
        />
      </VStack>

      <Divider />
      <HStack>
        <IconButton
          variant="outline"
          aria-label={t("common.navigate_back")}
          icon={<ArrowBackIcon />}
          onClick={onPrevious}
        />
        <Spacer />
        <Button
          onClick={handlePreviewButtonClick}
          aria-label={t("common.preview")}
          data-cy="btn-preview"
          rightIcon={<ArrowForwardIcon />}
        >
          {t("common.preview")}
        </Button>
      </HStack>
    </>
  );
};

const PreviewStep: FC<GenericStepProps> = ({ onContinue, onPrevious }) => {
  const { t } = useTranslation();

  const [ emailTemplate, setEmailTemplate ] = useState("");

  const [ smsTemplate, setSmsTemplate ] = useState("");

  useEffect(() => {
    api.getEmailTemplate()
      .then((template) => {
        setEmailTemplate(template.data);
      })
      .catch((err) => {
        console.log(err);
      });

    api.getSmsTemplate()
      .then((template) => {
        const smsTemplateDataHtml = `
          <html>
            <body>
              <style>
                body {
                  font-family: -apple-system, BlinkMacSystemFont,
                              "Segoe UI", "Roboto", "Oxygen",
                              "Ubuntu", "Cantarell", "Fira Sans",
                              "Droid Sans", "Helvetica Neue", sans-serif;
                  font-size: small;
                  white-space: pre-line;
                }
              </style>${template.data}
            </body></html>
        `;
        setSmsTemplate(smsTemplateDataHtml);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <>
      <Heading fontSize="3xl" textAlign={"center"}>
        {t("PreviewStep.message_preview")}
      </Heading>
      <Text fontSize="md">
        {t("PreviewStep.here_is_the_preview_of_the_message_that_will_be_sent")}
      </Text>

      <Tabs variant="soft-rounded" colorScheme="pink">
        <Center>
          <TabList>
            <Tab>{t("common.sms")}</Tab>
            <Tab>{t("common.email")}</Tab>
          </TabList>
        </Center>
        <TabPanels h={250} overflowY={"auto"}>
          <TabPanel>
            <Divider />
            <Text fontSize="sm" style={{whiteSpace: "pre-line"}} padding={3}>
              <iframe style={{
                backgroundColor: "white",
                whiteSpace: "pre-line"}} width={"100%"} height={250} srcDoc={smsTemplate} sandbox="" />
            </Text>
          </TabPanel>
          <TabPanel>
            <Divider />
            <iframe width={"100%"} height={250} srcDoc={emailTemplate} sandbox="" />
          </TabPanel>
        </TabPanels>
      </Tabs>

      <Divider />
      <HStack>
        <IconButton
          variant="outline"
          aria-label={t("common.navigate_back")}
          icon={<ArrowBackIcon />}
          onClick={onPrevious}
        />
        <Spacer />
        <Button onClick={onContinue} data-cy="btn-send">{t("common.send")}</Button>
      </HStack>
    </>
  );
};

const TipsToRememberPopover: FC = () => {
  const { t } = useTranslation();
  return (
    <Popover placement="top">
      <PopoverTrigger>
        <Text fontSize="lg">
          <a>{t("TipsToRemember.cant_remember_here_are_some_tips")}</a>
        </Text>
      </PopoverTrigger>
      <Box>
        <PopoverContent maxWidth="lg">
          <PopoverArrow />
          <PopoverCloseButton />
          <PopoverHeader data-cy="tips-to-remember-header">
            {t("TipsToRemember.popover_title")}
          </PopoverHeader>
          <PopoverBody>
            {t("TipsToRemember.popover_description")}:
            <UnorderedList>
              <ListItem>{t("TipsToRemember.popover_tip1")} </ListItem>
              <ListItem>
                {t("TipsToRemember.popover_tip2")}
              </ListItem>
              <ListItem>
                {t("TipsToRemember.popover_tip3")}
              </ListItem>
              <ListItem>
                {t("TipsToRemember.popover_tip4")}
              </ListItem>
            </UnorderedList>
            {t(
              "TipsToRemember.popover_final_text"
            )}
          </PopoverBody>
        </PopoverContent>
      </Box>
    </Popover>
  );
};

type SubmitConfirmationProps = {
  isOpen: boolean;
  onClose: () => void;
  onConfirmed: () => void;
};
const SubmitConfirmation: FC<SubmitConfirmationProps> = ({
  isOpen,
  onClose,
  onConfirmed,
}) => {
  const cancelRef = useRef(null);

  const { t } = useTranslation();

  return (
    <AlertDialog
      motionPreset="slideInBottom"
      onClose={onClose}
      isOpen={isOpen}
      leastDestructiveRef={cancelRef}
      isCentered
      closeOnOverlayClick={false}
    >
      <AlertDialogOverlay bg="blackAlpha.300" backdropFilter="blur(2px)" />

      <AlertDialogContent>
        <AlertDialogHeader>
          {t("SubmitConfirmation.should_we_send_the_notification")}
        </AlertDialogHeader>
        <AlertDialogBody>
          {t("SubmitConfirmation.permission_acceptance_text")}
        </AlertDialogBody>
        <AlertDialogFooter>
          <Button ref={cancelRef} onClick={onClose} data-cy="btn-no">
            {t("common.no")}
          </Button>
          <Button
            colorScheme="red"
            ml={3}
            onClick={() => {
              onConfirmed();
              onClose();
            }}
            data-cy="btn-yes"
          >
            {t("common.yes")}
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export const query = graphql`
  query {
    locales: allLocale {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export default NotifySomeonePage;
