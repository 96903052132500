import {
  FormControl,
  FormErrorMessage,
  HStack,
  Input,
  InputGroup,
  InputLeftElement,
  Select,
} from "@chakra-ui/react";
import { FC, useCallback, useEffect, useState } from "react";
import { EmailIcon, PhoneIcon } from "@chakra-ui/icons";
import { debounce } from "lodash";

type Props = {
  id: string;
  inputType: string;
  inputValue: string;
  error: string;
  onContactInputSet: (
    id: string,
    type: string,
    value: string,
    error: string
  ) => void;
};

const ContactInput: FC<Props> = ({
  id,
  inputType,
  inputValue,
  error,
  onContactInputSet,
}) => {
  const [type, setType] = useState(inputType);
  const [value, setValue] = useState(inputValue);

  const validateValue = useCallback((type: string, value: string): string => {
    if (value.length <= 3) {
      return "Is this correct? Seems too small";
    }

    const phoneRegex = /^\+[1-9][0-9]+$/;
    const emailRegex =
      // eslint-disable-next-line no-useless-escape
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (type === "sms" && !phoneRegex.test(value)) {
      return "Invalid phone number";
    } else if (type === "email" && !emailRegex.test(value)) {
      return "Invalid email address";
    }

    return "";
  }, []);

  const debouncedContactInputSet = useCallback(
    debounce(onContactInputSet, 400),
    []
  );

  useEffect(() => {
    debouncedContactInputSet(id, type, value, validateValue(type, value));
  }, [type, value]);

  return (
    <FormControl isInvalid={error != "" && value.length > 0}>
      <HStack spacing={5}>
        <Select
          alignSelf={"left"}
          width={120}
          onChange={(e) => setType(e.target.value)}
          value={type}
          isInvalid={false}
        >
          <option value="sms">SMS</option>
          <option value="email">Email</option>
        </Select>
        {type == "sms" && (
          <>
            <InputGroup>
              <InputLeftElement
                pointerEvents="none"
                // eslint-disable-next-line react/no-children-prop
                children={<PhoneIcon color="gray.300" />}
              />
              <Input
                type="tel"
                placeholder="+3581234567890"
                onChange={(e) => setValue(e.target.value)}
                value={value}
                data-cy="input-tel"
              />
            </InputGroup>
          </>
        )}
        {type == "email" && (
          <>
            <InputGroup>
              <InputLeftElement
                pointerEvents="none"
                // eslint-disable-next-line react/no-children-prop
                children={<EmailIcon color="gray.300" />}
              />
              <Input
                type="email"
                placeholder="someone@example.com"
                onChange={(e) => setValue(e.target.value)}
                value={value}
                data-cy="input-email"
              />
            </InputGroup>
          </>
        )}
      </HStack>
      <FormErrorMessage marginLeft={120}>{error}</FormErrorMessage>
    </FormControl>
  );
};

export default ContactInput;
