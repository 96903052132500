import { PlusSquareIcon } from "@chakra-ui/icons";
import { Box, Button, CloseButton, HStack, VStack } from "@chakra-ui/react";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { FC, useEffect, useState } from "react";
import { rnd } from "../utils";
import ContactInput from "./ContactInput";

type InputItem = {
  id: string;
  type: string;
  value: string;
  error: string;
};

type Props = {
  contactInputState: InputItem[];
  onContactInputStateChange: (contacts: InputItem[]) => void;
};

const ContactInputForm: FC<Props> = ({
  contactInputState,
  onContactInputStateChange,
}) => {
  const { t } = useTranslation();

  const [inputState, setInputState] = useState(contactInputState);

  const handleContactInputSet = (
    id: string,
    type: string,
    value: string,
    error: string
  ) => {
    setInputState(oldInputState => {
      const newInputState = [...oldInputState];
      newInputState.forEach((state) => {
        if (state.id === id) {
          state.type = type;
          state.value = value;
          state.error = error;
        }
      });
      return newInputState;
    })
  };

  const handleAddContactButtonClick = () => {
    setInputState(oldInputState => {
      const newInputState = [...oldInputState];
      newInputState.push({
        id: rnd(),
        type: "sms",
        value: "",
        error: "",
      });
      return newInputState;
    });
  };

  const handleRemoveContactButtonClick = (id: string) => {
    setInputState(oldInputState => {
      const newInputState = oldInputState.filter((state) => {
        return state.id != id;
      });
      return newInputState;
    });
  };

  useEffect(() => {
    onContactInputStateChange(inputState);
  }, [inputState]);

  return (
    <VStack spacing={5}>
      {inputState.map((state) => {
        return (
          <HStack
            key={state.id}
            spacing={5}
            alignContent={"left"}
            width={"full"}
          >
            <ContactInput
              id={state.id}
              inputType={state.type}
              inputValue={state.value}
              error={state.error}
              onContactInputSet={handleContactInputSet}
            />
            <CloseButton
              alignSelf={"start"}
              aria-label="Remove contact"
              onClick={() => handleRemoveContactButtonClick(state.id)}
            />
          </HStack>
        );
      })}

      <Box width="100%">
        <Button
          paddingLeft="0"
          size="md"
          variant="ghost"
          colorScheme={"red"}
          aria-label="Add contact"
          leftIcon={<PlusSquareIcon />}
          onClick={handleAddContactButtonClick}
          data-cy="btn-add-contact"
        >
          {t("ContactInputForm.add_contact")}
        </Button>
      </Box>
    </VStack>
  );
};

export default ContactInputForm;
